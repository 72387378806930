#section_masthead{
    padding-bottom: 60px;
    h1{
        margin-bottom: 0.2em;
        max-width: 1200px;
    }
    p{
        max-width: 400px;
    }
}

#section_promises{
    position: relative;
    h3{
        margin-bottom: 15px;
        position:absolute;
        top: 20px;
        z-index:2;
    }
    li{
        border-top: 1px solid var(--primary-color);
        padding: 20px;
        margin-bottom: 20px;
        margin-left: calc(var(--margin_x) * -1 + 20px);
        h4{
            margin-bottom: 15px;
        }
        p{
            width: 50%;
        }
        h4, p{
            margin-left: 50%;
        }
    }
}

@media screen and (max-width: 900px){
    #section_promises{
        h3{
            position:static;
        }
        li  {
            p{
                width: 100%;
            }
            h4, p{
                margin-left: 0;
            }
        }
    }
}

#section_features{
    margin-bottom:100px;
    p.s5{
        max-width: 450px;
    }
    .features{
        display:flex;
        flex-wrap:wrap;
        width: 100%;
        margin-top:60px;
    }
    .feature{
        text-align:center;
        width: 33%;
        margin: 50px 0;
        svg{
            transform: scale(0.8);
            path{
                stroke: var(--primary-color);
            }
        }
    }
}

@media screen and (max-width: 850px) {
    #section_features {
        .feature{
            width: 50%;
            margin: 30px 0px;
            p{
                margin: auto;
                font-size: 14px;
                line-height: 1.2;
                max-width: 100px;
            }
        }
        h5{
            font-size: 1.5rem;
            margin-bottom: 0.25em;
        }
    }
}

#section_carousel{
    .border-x4{
        padding: 15px;
        display: flex;
        flex-direction: column;
    }
    .beads{
        margin-top:15px;
        // .arrow{
        //     transform:translateY(-28%);
        // }
    }
    .img-x2{
        margin-top:10px;
        img{
            height: calc(50vw - var(--margin_x) - 15px);
            object-fit:cover;
            max-height: 80vh;
        }
    }
    a {
        opacity: 0;
        transition: opacity 1s; /* Adjust duration as needed */
        display: none;
    }
    a.active-carousel-item {
        opacity: 1;
        display: block;
    }
    .border-x4{
        position:relative;
    }
    a svg{
        transition: margin 300ms;
    }
    a:hover{
        color: white;
        svg{
            margin-left: 10px;
        }
        svg path{
            fill: white;
        }
    }
    a:hover:before{
        content:'';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: black;
        top:0px;
        left: 0px;
    }
    a:hover ~ .beads{
        .bead{
            border-color: white;
        }
        .bead.active{
            background-color: white;
        }
        svg path{
            fill: white;
        }
    }
}

@media screen and (max-width: 800px){
    #section_carousel{
        svg{
            width: 18px;
            margin-left: 5px;
            transform: translateY(1px);
        }
    }
}

@media screen and (max-width: 600px){
    #section_carousel{
        .img-x2{
            display:block;
            width: 100%;
            img{
                width: 100%;
                height: 30vh;
                display:block;
            }
        }
        .beads{
            margin-bottom: 30px;
            gap: 10px;
        }
    }
}

#section_about{
    margin: 100px 0;
    a{
        display: flex;
        transition:  200ms;
    }
    a:hover{
        // background: var(--primary-color);
        // color: white;
        .link{
            // color: white;
            // border-color: white;
            svg {
                margin-left: 20px;
                path{
                    // fill: white;
                }
            }
        }
    }
    .box-inner{
        padding: 15px;
    }
    img{
        max-width: 400px;
        min-height: 400px;
        height:100%;
        width: 100%;
        object-fit: cover;
    }
    h2{
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1000px){
    #section_about{
        a{
            display: block;
        }
        img{
            max-width: 100%;
            max-height: 400px;
            margin-top: 10px;
            display: block;
        }
    }
}
@media screen and (max-width: 600px){
    #section_about{
        h2{
            font-size: 2rem;
        }
        img{
            max-height: 300px;
        }
    }
}

#section_overview{
    margin-top:100px;
    margin-bottom: 100px;
    h2{
        margin-bottom: 50px;
        margin-top:10vh;
    }
    p{
        max-width: 500px;
    }
    .infographic{
        width: 50%;
        height: 90vh;
        position:relative;
        > div{
            position:absolute;
        }
        img{
            filter: saturate(0);
            border-radius: 10px;
            object-fit: cover;
            opacity: 0.9;
        }
        .main-img{
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            width: 294px;
        }
        div:nth-child(2):not(.data){
            top: 10%;
            left: 60%;
            img{
                width: 113px;
                height: 136px;
                object-fit: cover;
            }
        }
        div:nth-child(3){
            top: 60%;
            right: 10%;
            img{
                width: 86px;
                height: 57px;
                object-fit: cover;
            }
        }
        div:nth-child(4){
            bottom: 10%;
            left: 0%;
            img{
                width: 134px;
                height: 163px;
                object-fit: cover;
            }
        }
        table{
            font-family: 'Trispace', monospace;
            font-style: normal;
            font-weight: 300;
            font-size: 8px;
            line-height: 1.1;
            color: var(--primary-color);
            letter-spacing: 0;
            *{
                display:block;
                text-align:left;
                font-weight: 300;
            }
            th{
                text-transform: uppercase;
            }
            tr{
                margin-bottom: 1em;
            }
        }
        .data{
            position:absolute;
            top:0px;
            right:-10px;
            transform:translateX(100%);
            // left
            // right: 40px;
        }
    }
}

@media screen and (max-width: 885px){
    #section_overview{
        display:block;
        h2{
            max-width:  500px;
        }
        .infographic .main-img{
            left:33%;
        }
        .infographic{
            width: 100%;
            max-width: 600px;
            margin:auto;
        }
    }
}

@media screen and (max-width: 700px){
    #section_overview{
        .infographic .main-img{
            width:190px;
            left:50%;
        }
        .infographic div:nth-child(3){
            right:20%;
        }
        .infographic div:nth-child(2):not(.data){
            top:0%;
            left:10%;
        }
        .infographic div:nth-child(2):not(.data) .data{
            top: 65px
        }
        .infographic div:nth-child(3) .data{
            top: 45px;
        }
        .data{
            mix-blend-mode: multiply;
        }
    }
}

@keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  .typed-on {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    animation: typing 1s steps(10, end) both;
    width: 0; 
    will-change: width;
  }
  

  #section_testimonials{
    display:flex;
    gap: 20px;
    margin-bottom: 200px;
    h2{
        flex: 1;
    }
    .testimonials{
        flex: 4;
        position: relative;
        min-height: 200px;
    }
    .testimonial-logo{
        width: 110px;
        margin-bottom: 20px;
    }
    .testimonial-quote{
        margin-bottom: 20px;
    }
    .testimonial-quote, .testimonial-credit{
        position: relative;
    }
    .testimonial-quote:before{
        content: "“";
        position:absolute;
        transform: translateX(-100%);
    }
    .testimonial-credit:before{
        content: "— ";
        position:absolute;
        transform: translateX(-100%);
    }

    .beads{
        height: 20px;
        align-items:center;
    }


    /* Hide all testimonials */
    .testimonials div {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; /* Set to your preferred width or keep it 100% */
        max-width: 700px;
    }
    
    .testimonials div.active {
        opacity: 1;
        position: relative; /* Set the active div to flow normally in the document */
    }

  }

  @media screen and (max-width: 700px){
    #section_testimonials{
        display:block;
        position: relative;
        .testimonials{
            width: 100%;
            margin-top: 50px;
        }
        .beads{
            position:absolute;
            top: 15px;
            right: var(--margin_x);
        }
    }
  }

  #section_insights{
    margin-bottom: 200px;
    h2{
        margin-bottom: var(--gap);
    }
  }

  @media screen and (max-width: 650px){
    .section-sector-cards{
        h2{
            margin-bottom: 15px;
        }
    }
  }